<template>
  <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl lg:text-center">
      <Title>
        ABN Finder Tool
      </Title>
      <Subtitle>
      Have you lost or forgotten your ABN?
      </Subtitle>
      <p class="mt-6 text-lg leading-8 text-gray-600">
      Instantly find your ABN at no cost by using the form below.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 lg:max-w-none lg:grid-cols-3">
        <div v-for="feature in features" :key="feature.name" class="flex flex-col">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <component :is="feature.icon" class="h-5 w-5 flex-none text-stone-600" aria-hidden="true" />
            {{ feature.name }}
          </dt>
          <dd class="mt-1 lg:mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">{{ feature.description }}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, EnvelopeOpenIcon } from '@heroicons/vue/24/outline'
import Title from '@components/Title.vue'
import Subtitle from '@components/Subtitle.vue'

const features = [
  {
    name: 'Online single form',
    description: 'Simply use the registration form to provide the necessary information for locating your ABN. The whole process will only take about a minute.',
    icon: ClipboardDocumentListIcon,
  },
  {
    name: 'Automatic Review & Proceed',
    description: 'Once we receive the details, our team of experienced experts will promptly review your application.',
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: 'ABN Delivered by email',
    description: 'You will see the ABN and its status displayed on the screen, and we will also send it to you via email, typically within 5 minutes.',
    icon: EnvelopeOpenIcon,
  },
]
</script>
