<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-0 sm:px-6 lg:px-8">
      <div class="lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="lg:col-span-5">
          <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            What’s next?
          </h2>
          <p class="mt-4 text-base leading-7 text-gray-600">
            We strive to make the process seamless and efficient for you.
            Should you have any further questions or require assistance, our
            dedicated
            <a href="mailto:emailing@abn-register.com.au" class="font-semibold text-stone-600 hover:text-stone-500">support team</a> is here to help.
          </p>
        </div>
        <div class="mt-10 lg:col-span-7 lg:mt-0">
          <dl class="space-y-10">
            <div v-for="faq in faqs" :key="faq.question">
              <dt class="text-base font-semibold leading-7 text-gray-900">{{ faq.question }}</dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">{{ faq.answer }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const faqs = [
  {
    question: 'ABN Display: ',
    answer: 'The assigned Australian Business Number (ABN) will be immediately displayed on your screen, confirming its status.',
  },
  {
    question: 'Email Confirmation: ',
    answer: 'A copy of your ABN will also be promptly sent to the email address you provided during the registration process.',
  },
  {
    question: 'Inactive or Cancelled ABN: ',
    answer: 'In the event that your ABN is found to be inactive or cancelled, we will provide clear instructions on how you can proceed with the registration process to reactivate or obtain a new ABN.',
  },
]
</script>
